import React from "react";
import { graphql, navigate } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LeadGenerationForm, PageTemplateWrapper, TitleWrapper } from "@resourcehub/resourcehub-components";
import configGenerator from "@resourcehub/resourcehub-configuration";
import { leadGenerationStore } from "@resourcehub/resourcehub-data";

const { leadGenerationActions, leadGenerationConstants, leadGenerationSelectors } = leadGenerationStore;

const FormLayout = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    padding-top: 30px;
    margin-bottom: 60px;

    background-color: #0098db;
    border-radius: 60%/0 0 150px 150px;

    > div:nth-of-type(2) {
        margin-bottom: 20px;
    }

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        border-radius: 0;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        border-radius: 0;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        border-radius: 60%/0 0 150px 150px;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        border-radius: 60%/0 0 150px 150px;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        border-radius: 60%/0 0 150px 150px;
    }
`;

class PricingRequestPage extends React.Component {
    constructor(props) {
        super(props);
        const { data } = props;
        const { site } = data;
        const config = configGenerator({
            market: site.siteMetadata.market,
            site: "education-secondary",
            locale: site.siteMetadata.lang,
            page: "pricing-request"
        });
        this.state = {
            config
        };
    }
    componentDidUpdate() {
        const { schedule_appointment, submission_status } = this.props;
        if (submission_status === leadGenerationConstants.submissionStatus.success) {
            if (schedule_appointment === true) {
                navigate("/pricing-request-schedule");
            } else {
                navigate("/pricing-request-complete");
            }
        }
    }

    render() {
        const { config } = this.state;
        const { market, vertical, site, locale } = config;
        const context = { market, vertical, site, locale };
        return (
            <PageTemplateWrapper config={config}>
                <FormLayout>
                    <TitleWrapper {...config.page.content.title} />
                    <TitleWrapper {...config.page.content.sub_title} />
                    <LeadGenerationForm {...config.page.content.lead_generation_form} context={context} />
                </FormLayout>
            </PageTemplateWrapper>
        );
    }
}

PricingRequestPage.propTypes = {
    data: PropTypes.any
};

// Selectors
const mapStateToProps = (state) => ({
    submission_status: leadGenerationSelectors.getSubmissionStatus(state),
    schedule_appointment: leadGenerationSelectors.getScheduleAppointment(state)
});

export default connect(mapStateToProps, leadGenerationActions)(PricingRequestPage);

export const pageQuery = graphql`
    query {
        site {
            ...SiteFragment
        }
    }
`;
